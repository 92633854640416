import { useEffect, useState } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Col, Form, Row, Button } from "react-bootstrap";
import ReUsable from "../components/ReUsable"
import brandingIcon from "../assets/images/branding-icon.webp"
import webDevelopment from "../assets/images/web-development-icon.webp"
import socialMedia from "../assets/images/social-media-icon.webp"
import trafficIcon from "../assets/images/lead-gen.webp"
import appDevelopment from "../assets/images/app-dev-icon.webp"
import seoIcon from "../assets/images/seo-icon.webp"
import vdoDevelopIcon from "../assets/images/video-develop-icon.webp"
import cctvMonitoring from "../assets/images/cctv-icon.webp"
import contentWriting from "../assets/images/content-writting-icon.webp"
import PartnersOTP from "../assets/images/partners-otp.webp"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/fontawesome-free-solid";

const About = ({ handleModalShow, sendEmail, sendForm }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }

  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.AboutMetaTitle ? meta[0]?.AboutMetaTitle : "About"}</title>
        <meta name="description" content={meta && meta[0]?.AboutMetaDesc ? meta[0]?.AboutMetaDesc : "About Description"} />
      </Helmet>
      <div className="about-banner d-flex justify-content-center align-items-center">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-lg-start text-center">
              <h1>
                <b>
                  The 5-Stage <span className="text-green">Outsourcing Process </span>
                </b>
              </h1>
              <p className="text-white mb-3 pt-3">Clients often have critical concerns when considering outsourcing services to a third-
                party organization:</p>
              <ul>
                <li className="text-white"><strong>Infrastructure and Resources</strong>: They question whether the outsourcing partner
                  possesses the necessary infrastructure, resources, expertise, and experience to deliver
                  superior results.</li>
                <li className="text-white"><strong>Work Approach</strong>: Clients seek insight into the outsourcing company&#39;s work approach,
                  wanting assurance that it aligns with their expectations for achieving enhanced
                  outcomes.</li>
              </ul>
            </div>
            <div className="col-md-5 d-lg-block d-none">
              <div className="banner__form card border-0">
                <div className="card-body">
                  <h4 className="mb-4 text-white text-center">Let's Get <br /> Started</h4>
                  <Form ref={sendForm} onSubmit={sendEmail}>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_name"
                            placeholder="Full Name"
                            className="shadow-none"
                            required
                          />
                          <Form.Control
                            type="text"
                            name="PageNameTracking"
                            hidden
                            value="About Page"
                            className="shadow-none"
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            name="user_email"
                            placeholder="Enter Your email"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="tel"
                            name="user_phone_number"
                            placeholder="phone number"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                      <Col>
                        <Form.Group className="mb-3" controlId="formBasicPassword">
                          <Form.Control
                            type="text"
                            name="user_company_name"
                            placeholder="company name"
                            className="shadow-none"
                            required
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control type="text" name="subject" placeholder="Subject"
                        className="shadow-none"
                        required />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="formBasicPassword">
                      <Form.Control
                        as="textarea"
                        name="user_message"
                        rows={5}
                        className="shadow-none"
                        placeholder="type Message"
                        required
                      />
                    </Form.Group>
                    <div className="text-center">
                      <Button type="submit" className="popup-submit">
                        <span> Submit</span>
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="steps-process py-5">
        <div className="container">
          <p className="text-center mb-4">We stand at the forefront of the outsourcing services industry, guided by a well-structured 5-
            stage outsourcing methodology that guarantees timely and cost-effective delivery of our
            services.</p>
          <div className="card mb-3 shadow-sm border-0 bg-white">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3><b>01</b> <span>Establish <br />Contact</span></h3>
                </div>
                <div className="col-md-6">
                  <ul className="mb-0">
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> You fill the inquire form</li>
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> The sales team calls or emails you</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 shadow-sm border-0 bg-white">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3><b>02</b> <span>Requirement <br />Analysis</span></h3>
                </div>
                <div className="col-md-6">
                  <ul className="mb-0">
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> You fill the inquire form</li>
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> The sales team calls or emails you</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 shadow-sm border-0 bg-white">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3><b>03</b> <span>Pricing &amp; <br />Contracting</span></h3>
                </div>
                <div className="col-md-6">
                  <ul className="mb-0">
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> You fill the inquire form</li>
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> The sales team calls or emails you</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 shadow-sm border-0 bg-white">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3><b>04</b> <span>Project <br />Initiation</span></h3>
                </div>
                <div className="col-md-6">
                  <ul className="mb-0">
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> You fill the inquire form</li>
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> The sales team calls or emails you</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="card mb-3 shadow-sm border-0 bg-white">
            <div className="card-body">
              <div className="row align-items-center">
                <div className="col-md-6">
                  <h3><b>05</b> <span>Project <br />Steady State</span></h3>
                </div>
                <div className="col-md-6">
                  <ul className="mb-0">
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> You fill the inquire form</li>
                    <li><FontAwesomeIcon icon={faArrowAltCircleRight} className="me-2" /> The sales team calls or emails you</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="partner-otp py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6">
              <h3 className="mb-3">Partner with O2P</h3>
              <p>At O2I, we boast a robust infrastructure designed to facilitate seamless project
                transitions from inception to completion. Additionally, we&#39;ve integrated industry best
                practices for information security, ensuring comprehensive 360° Information Security
                and Confidentiality Assurance for all our clients.</p>
              <p>If you&#39;re interested in outsourcing to O2I, kindly complete our inquiry form. Our
                dedicated sales executives will promptly reach out to you. Even if you&#39;re not yet
                prepared to explore outsourcing, we welcome you to contact us for insights into the
                benefits of outsourcing to India. If you have concerns about transitioning your project to
                Outsource2india, please review our well-defined transition management process.</p>
              <p>Experience the advantages of outsourcing firsthand by enrolling in one of O2I&#39;s Free
                Trial Programs.&quot;</p>
            </div>
            <div className="col-md-6">
              <img src={PartnersOTP} alt="" className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      {/* service section start  */}
      <div className="services py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 title">
              <h2>
                NO SCIENCE, NO SECRET,
                <br /> ONLY HIGH-QUALITY SERVICES.
              </h2>
              <div className="my-4 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
            <div className="col-md-6 content">
              <p>
                Located in Karachi, Pakistan, we began with a bigger dream of
                offering high-quality digital solutions across the globe. We
                assist our clients in achieving their business objectives by
                successfully outsourcing their business processes and non-core
                functions.
              </p>
              <br />
              <p>
                We make the most of our people, processes and latest technology
                to catapult our clients to achieve their business potential. We
                are now serving millions of businesses worldwide and providing
                them high quality digital services. Being the fastest growing IT
                agency, we welcome you to join hands.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* service section end  */}

      {/* project section start  */}
      <div className="project py-5">
        <div className="container">
          <div className="title text-center">
            <h2 className="mb-3"><span className="animate">Services</span>
              Outsource Any Of Your Project To Us.</h2>
            <p>
              Our team is actively involved in providing high-quality services
              to both local and international clients. With our unbeatable
              outsourcing solutions and cutting-edge technologies not only does
              your company remain competitive, but your clients stay happy and
              satisfied
            </p>
          </div>

          <div className="row pt-5">
            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Creative Designing</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={brandingIcon}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Web Development</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={webDevelopment}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Social Media Marketing</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={socialMedia}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Lead Generation</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={trafficIcon}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>App Development</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={appDevelopment}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Search Engine Optimization</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={seoIcon}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Video Development</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={vdoDevelopIcon}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>CCTV Monitoring</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={cctvMonitoring}
                  />
                </div>
              </NavLink>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-12 col-12">
              <NavLink to="" className="pro-box align-items-center row">
                <div className="name col">
                  <h4>Content Writing</h4>
                </div>
                <div className="imgbx col-auto">
                  <img
                    className="img-fluid"
                    src={contentWriting}
                  />
                </div>
              </NavLink>
            </div>
          </div>

          <div className="mt-5 text-center">
            <button className="main-btn-tn">
              <NavLink to="#" onClick={handleModalShow}>
                <span>Get Started</span>
              </NavLink>
            </button>
          </div>
        </div>
      </div>
      {/* project section end */}

      {/* world wide section start  */}
      <div className="world-wide py-5">
        <div className="container">
          <div className="title text-center">
            <h2 className="mb-3">
              <span className="animate">Worldwide</span>
              We Have Offices & Teams
              <br />
              Around The World Serving Different Markets
            </h2>
            <p>
              Our group's core functions are carried out in Pakistan, including
              all B2B marketing, branding design, digital marketing, web design
              solutions, web development, mobile app development, and virtual
              reality services. Besides, we have our direct and partner offices
              worldwide, including the United States, United Kingdom, China,
              United Arab Emirates, Australia, and Singapore.
            </p>
          </div>
        </div>
      </div>
      {/* world wide section end  */}

      <ReUsable handleModalShow={handleModalShow} />
    </>
  );
};

export default About;
