import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const VideoDevelopment = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.VideoDevelopmentMetaTitle ? meta[0]?.VideoDevelopmentMetaTitle : "Video Development"}</title>
        <meta name="description" content={meta && meta[0]?.VideoDevelopmentMetaDesc ? meta[0]?.VideoDevelopmentMetaDesc : "Engage your audience with professional video development, production, and animation services. Learn how our team delivers powerful, creative video solutions"} />
        <meta name="keywords" content={meta && meta[0]?.VideoDevelopmentMetaKey ? meta[0]?.VideoDevelopmentMetaKey : "Video development services, video production services, video animation services, animation services outsourcing, hire video production, video animation outsourcing"} />

      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Create Engaging Content with Professional
                  Video Development Services</b>
              </h1>
              <p className="pt-2 text-white">
                In the digital era, video content is king. Whether you’re promoting a product, explaining a
                concept, or telling a story, video is the most effective way to engage your audience. With  <strong>
                  video
                  development services,</strong> you get professional, high-quality content that captivates viewers and
                drives results. From concept to final cut, our expert team creates videos that align with your
                brand and communicate your message powerfully.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-right py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Video Development Services Matter for Your Business
            </h2>
            <p className="text-white">
              A compelling video can capture attention in seconds and leave a lasting impression. Whether
              you’re looking to boost brand awareness, increase conversions, or improve engagement,
              professionally developed videos are crucial for effective communication..
            </p>
            <p className="text-white pt-2">
              Here’s why video development services are essential for modern businesses:
            </p>
            <ol className="text-white pt-3">
              <li>
                <strong>Enhanced Engagement:</strong> Video is a dynamic medium that keeps audiences interested and
                engaged, increasing the chances they’ll remember your message.
              </li>
              <li>
                <strong>Increased Conversions:</strong> A well-crafted video can simplify complex information and
                motivate viewers to take action, from signing up for a newsletter to making a purchase.
              </li>
              <li>
                <strong>Shareable Content:</strong> Video content is highly shareable on social media and other
                platforms, expanding your reach and boosting brand awareness.
              </li>
            </ol>
            <p className="text-white">With expert <strong>video development services,</strong> you can create compelling videos that connect with
              your audience and drive measurable results.</p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire Professional Video Production Services?</h3>
              <p className="pt-3">
                Creating a video that looks professional and delivers a powerful message requires more than just
                shooting footage. <strong>Video production services</strong> bring together scriptwriting, filming, editing, and
                post-production to create a polished, high-quality video that achieves your business goals.
              </p>
              <p className="pt-3">
                Here’s how <strong>video production services</strong> can benefit your business:
              </p>
              <ol>
                <li className="pt-3">
                  <strong>High-Quality Content:</strong> With professional video production, you get top-notch visuals,
                  sound, and storytelling that engage your audience and enhance your brand image.
                </li>
                <li>
                  <strong>Custom Video Solutions:</strong> Video production teams work with you to create videos
                  tailored to your specific needs, whether you’re creating a product demo, an explainer
                  video, or a brand story.
                </li>
                <li>
                  <strong>Full-Service Production:</strong> From pre-production planning to filming, editing, and final
                  delivery, video production services cover every step of the process to ensure your video
                  meets the highest standards.
                </li>
              </ol>
              <p className="pt-3">
                By investing in <strong>video production services,</strong> you can create impactful videos that not only
                entertain but also inform and convert your audience.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Video Animation Services Can Elevate Your Content</h3>
              <p className="pt-3 text-white">
                In addition to live-action video, <strong>video animation services</strong> offer an exciting and versatile way to
                communicate your message. Animated videos are perfect for explaining complex ideas, creating
                eye-catching advertisements, or adding a fun, creative twist to your brand content.
              </p>
              <p className="pt-3 text-white">
                Here’s why video animation services are a game-changer:
              </p>
              <ol className="pt-3 text-white">
                <li>
                  <strong>Complex Ideas Simplified:</strong> Animation allows you to break down complicated concepts
                  into engaging, easy-to-understand visuals.
                </li>
                <li>
                  <strong>Creative Freedom:</strong> With animation, the sky's the limit. You can create imaginative
                  worlds, characters, and visual effects that are impossible to achieve with live-action video
                </li>
                <li>
                  <strong>Increased Engagement:</strong> Animated videos stand out and are often more engaging for
                  viewers, making them perfect for social media, marketing campaigns, and educational
                  content.
                </li>
              </ol>
              <p className="pt-3 text-white">From explainer videos to animated commercials, video animation services give your business a
                creative edge in capturing your audience’s attention.</p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Outsource Your Animation Services?</h3>
              <p className="pt-3">
                Managing in-house animation teams can be costly and time-consuming. By outsourcing
                animation services, you gain access to a global network of skilled animators who can bring your
                vision to life while saving on overhead costs.
              </p>
              <p className="pt-3">
                Here’s why <strong>animation services outsourcing</strong> is the smart choice:
              </p>
              <ol className="pt-3">
                <li>
                  <strong>Cost-Effective Solutions:</strong> Outsourcing animation services allows you to access top-
                  quality work without the high costs of hiring and managing an in-house team.
                </li>
                <li>
                  <strong>Specialized Expertise:</strong> Animation requires a specific skill set, and outsourcing gives you
                  access to experienced professionals with expertise in various animation styles, from 2D
                  to 3D, motion graphics, and beyond.
                </li>
                <li>
                  <strong>Faster Turnaround:</strong> Outsourcing animation projects can significantly speed up
                  production times, allowing you to deliver high-quality content on tight deadlines.
                </li>
              </ol>
              <p className="pt-3">
                Whether you need an animated video for a marketing campaign or an entire series for an
                educational platform, <strong>animation services outsourcing</strong> is a cost-efficient, scalable solution for
                businesses of all sizes.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">The Benefits of Video Animation Outsourcing</h3>
              <p className="pt-3 text-white">
                Creating professional-grade animations in-house can be a major investment in time and
                resources. By choosing <strong>video animation outsourcing,</strong>, you can delegate the creative work to a
                team of specialists who can deliver high-quality animated content efficiently.
              </p>
              <p className="pt-3 text-white">Here’s why video animation outsourcing works for your business:</p>

              <ol  className="pt-3 text-white">
                <li><strong>Access to Global Talent:</strong> Outsourcing gives you access to animators with diverse skill
                  sets, ensuring you can find the right style and expertise for your project.</li>
                <li><strong>Reduced Costs:</strong> Animation outsourcing is more cost-effective than building and
                  maintaining an in-house animation team, allowing you to allocate resources to other areas
                  of your business.</li>
                <li><strong>Scalable Solutions:</strong> Whether you need a single animation or a series of videos,
                  outsourcing allows you to scale up or down based on project needs.</li>

              </ol>
              <p className="pt-3 text-white">With video animation outsourcing, you get high-quality animated content that meets your
                business’s needs while saving time and money.</p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={crm}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={erp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={cloud}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Partner with Us for Video Development and Animation Services?</h3>
              <p className="pt-3">
                At O2P, we offer comprehensive <strong>video development services</strong> that cover
                everything from live-action production to animation. Our experienced team of creatives works
                closely with you to ensure your video not only looks great but also delivers results.
              </p>
              <p className="pt-3">
                We Specialize in:
              </p>
              <ol className="pt-3">
                <li>
                  <strong>Video Production Services:</strong> High-quality video production tailored to your specific
                  goals, from pre-production planning to post-production editing.
                </li>
                <li>
                  <strong>Video Animation Services:</strong> Engaging, imaginative animations that simplify complex
                  concepts and enhance your brand’s visual storytelling.
                </li>
                <li>
                  <strong>Animation Services Outsourcing:</strong>Cost-effective solutions that give you access to expert
                  animators without the overhead of managing an in-house team.
                </li>
              </ol>
              <p className="pt-3">
                Whether you need a product video, a brand story, or an animated explainer, our team is here to
                bring your vision to life.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={woo}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={magento}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Ready to Create Engaging Video Content?</h3>
              <p className="pt-3 text-white">
                If you’re looking for expert <strong> video development</strong> and <strong>animation services</strong> that drive engagement
                and deliver results, we’re here to help. Let us create the compelling content you need to capture
                your audience’s attention and enhance your brand’s message.
              </p>
              <p className="pt-3 text-white">
              Contact us today to learn more about our<strong> video production</strong> and <strong>animation services</strong>
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Web Development
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default VideoDevelopment;
