import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const AmazonMarketing = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.AmazonMarketingMetaTitle ? meta[0]?.AmazonMarketingMetaTitle : "Amazon Marketing"}</title>
        <meta name="description" content={meta && meta[0]?.AmazonMarketingMetaDesc ? meta[0]?.WebDevelopmentMetaDesc : "Unlock your brand's potential on Amazon with expert Amazon marketing services. Learn how Amazon marketing agencies can help boost your sales and visibility"} />
        <meta name="keywords" content={meta && meta[0]?.AmazonMarketingMetaKey ? meta[0]?.AmazonMarketingMetaKey : "Amazon marketing, Amazon marketing agencies, hire Amazon marketing expert, outsource Amazon marketing, Amazon marketing services"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Elevate Your Brand with Expert Amazon
                  Marketing Services</b>
              </h1>
              <p className="pt-2 text-white">
                Selling on Amazon is one of the most powerful ways to grow your brand and reach millions of
                potential customers. However, navigating the platform’s competitive marketplace requires more
                than just listing your products—it demands a tailored Amazon marketing strategy that gets
                results. Whether you're launching a new product or looking to boost existing sales, partnering
                with the right <strong> Amazon marketing agency</strong> can help you stand out and succeed.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-center py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Amazon Marketing Matters
            </h2>
            <p className="text-white">
              <strong> Amazon marketing</strong> involves a comprehensive approach to promote and position your products
              effectively within Amazon’s ecosystem. This goes beyond just adding keywords to your product
              listings. It’s about optimizing your listings, running effective ad campaigns, and utilizing
              Amazon's tools to increase visibility and conversions.
            </p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Cost-Effective Solutions</h3>
              <p className="pt-3">
                Our outsourced web development services offer a cost-effective alternative to in-house development. You'll save on recruitment, infrastructure, and training costs.
              </p>
              <p className="pt-3"
              >Here’s why a solid Amazon marketing strategy is essential:</p>
              <ol className="pt-2">
                <li><strong>Increase Visibility: </strong> With millions of products available on Amazon, it’s easy to get lost
                  in the crowd. Effective Amazon marketing helps your products rank higher in search
                  results and appear more often in front of your target customers.</li>
                <li><strong>Boost Sales: </strong> From sponsored product ads to display advertising, Amazon marketing
                  strategies can drive more traffic to your listings and turn shoppers into buyers..</li>
                <li><strong>Build Brand Credibility: </strong> A well-optimized Amazon storefront with strong branding and
                  compelling visuals gives your business a professional, trustworthy presence on the
                  platform.</li>
              </ol>
              <p className="pt-3">Whether you’re new to Amazon or a seasoned seller, having the right marketing strategy can
                drastically improve your sales and return on investment.</p>

            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Ready to Hire an Amazon Marketing Agency? </h3>
              <p className="pt-3 text-white">
                Handling all the intricacies of <strong>Amazon marketing</strong>can be overwhelming. That’s where
                partnering with an experienced <strong> Amazon marketing agency</strong> makes all the difference. A good
                agency knows the ins and outs of Amazon’s algorithms and tools, helping you maximize your
                presence and profits on the platform.
              </p>
              <p className="pt-3 text-white">
                Here’s how an Amazon marketing agency can help:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Expert Knowledge:</strong> Amazon’s algorithms and advertising platform are constantly
                  evolving. An experienced agency stays up-to-date with these changes, ensuring your
                  marketing strategy is always optimized for success.</li>
                <li><strong>Optimized Listings:</strong> From keywords to product descriptions and imagery, agencies make
                  sure every element of your product listings is crafted to attract clicks and convert sales.</li>
                <li><strong>Effective Advertising:</strong> Whether it’s Sponsored Products, Sponsored Brands, or Amazon
                  DSP, an agency will manage your ad campaigns, helping you get the most out of your
                  advertising budget..</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Outsourcing Amazon Marketing is a Smart Move</h3>
              <p className="pt-3">
                When it comes to running your Amazon store, there’s a lot to juggle—inventory management,
                customer service, fulfillment, and more. Outsourcing your Amazon marketing to professionals
                lets you focus on running your business while they handle the complex world of Amazon
                optimization and advertising.
              </p>
              <p className="pt-3">Here’s why outsourcing to an <strong> Amazon marketing agency</strong> makes sense:</p>
              <ol className="pt-3">
                <li><strong>Save Time & Resources:</strong> Managing Amazon campaigns and optimizing product listings
                  is a full-time job. Outsourcing lets you leverage the expertise of professionals without
                  having to hire an in-house team.</li>
                <li><strong>Maximize Sales:</strong> A specialized Amazon marketing agency knows how to craft
                  campaigns that increase your product’s visibility, driving more sales and ultimately
                  growing your bottom line.</li>
                <li><strong>Tailored Strategies:</strong> Your business is unique, and so is your Amazon marketing strategy.
                  Outsourcing allows you to work with experts who will develop a customized plan that fits
                  your business goals.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">The Benefits of Partnering with Amazon Marketing Agencies</h3>
              <p className="pt-3 text-white">
                Not all Amazon marketing strategies are created equal. That’s why partnering with the right
                <strong> Amazon marketing agency</strong> is critical to your success on the platform.
              </p>
              <p className="pt-3 text-white">Here’s what you can expect when you hire an agency:</p>
              <ol className="pt-3 text-white">
                <li>
                  <strong>Data-Driven Strategies:</strong> Amazon marketing agencies use analytics and data insights to
                  continuously refine your strategy, improving everything from keyword targeting to ad
                  spend allocation.
                </li>
                <li>
                  <strong>Increased Conversions:</strong> From listing optimization to managing reviews, agencies ensure
                  your Amazon store is built to convert visitors into customers.
                </li>
                <li>
                  <strong>Full Campaign Management:</strong> From listing optimization to managing reviews, agencies ensure
                  your Amazon store is built to convert visitors into customers.
                </li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Outsource Your Amazon Marketing Needs?</h3>
              <p className="pt-3">
                Outsourcing your Amazon marketing efforts allows you to focus on growing your business while
                leaving the intricacies of the platform to the experts. Here’s why it works:
              </p>
              <ol className="pt-3">
                <li><strong>Specialized Expertise:</strong> Amazon marketing requires a unique skill set that combines
                  knowledge of e-commerce, SEO, and paid advertising. Outsourcing gives you access to
                  specialists who understand every aspect of the platform.</li>
                <li><strong>Scalability:</strong> As your business grows, so do your marketing needs. Outsourcing allows
                  you to easily scale up your marketing efforts without the hassle of hiring more full-time
                  staff.</li>
                <li><strong>Cost-Effective:</strong> Hiring an agency is often more cost-effective than building an internal
                  marketing team. You get the expertise you need without the overhead costs.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Work with Us?</h3>
              <p className="pt-3 text-white">
                At Outsourcing to Pakistan, we specialize in connecting businesses with the right <strong> Amazon
                  marketing experts.</strong> Whether you’re looking to increase your sales, optimize your product
                listings, or launch a new product on Amazon, we have the resources to help.
              </p>
              <p className="pt-3 text-white">
                Our team of seasoned professionals has years of experience in navigating the Amazon
                marketplace, managing everything from keyword research and listing optimization to running
                high-performance ad campaigns. We understand what it takes to succeed on Amazon, and we’re
                here to help you every step of the way.
              </p>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Boost Your Amazon Sales?</h3>
              <p className="pt-3">
                Don’t let your products get lost in the shuffle. Partner with us to supercharge your Amazon
                marketing efforts and take your business to new heights.
              </p>
              <p>Get in touch today to learn more about how our <strong>outsourcing services</strong> can connect you with top-
                tier Amazon marketing agencies and experts. Let us help you grow your brand and maximize
                your profits on Amazon.</p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Amazon Marketing
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default AmazonMarketing;
