import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const StaffAugmentation = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.StaffAugmentationMetaTitle ? meta[0]?.StaffAugmentationMetaTitle : "Staff Augmentation"}</title>
        <meta name="description" content={meta && meta[0]?.StaffAugmentationMetaDesc ? meta[0]?.StaffAugmentationMetaDesc : "Scale your team efficiently with expert staff augmentation services. Learn how IT staff augmentation and outsourcing can meet your business's evolving needs"} />
        <meta name="keywords" content={meta && meta[0]?.StaffAugmentationMetaKey ? meta[0]?.StaffAugmentationMetaKey : "Staff augmentation services, IT staff augmentation, staff outsourcing, hire staff augmentation, staff outsourcing providers"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Flexible and Efficient Solutions with Expert
                  Staff Augmentation Services</b>
              </h1>
              <p className="pt-2 text-white">
                In today’s fast-paced business environment, you need to be agile. Whether you’re expanding
                operations, launching new projects, or meeting seasonal demand, having the right talent on hand
                can make all the difference. <strong>Staff augmentation services</strong> offer a flexible, cost-effective way to
                scale your workforce without the long-term commitments of traditional hiring. With access to
                highly skilled professionals, you can quickly fill critical roles and ensure your business stays
                competitive.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-left py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Staff Augmentation Services are Essential
            </h2>
            <p className="text-white">
              <strong>Staff augmentation </strong> allows you to bring in the talent you need, when you need it, without the
              hassle of a lengthy hiring process. It’s a strategic solution that gives your business the flexibility
              to adjust to fluctuating workloads, market demands, or specialized project needs.
            </p>
            <p className="text-white pt-3">
              Here’s why  <strong>staff augmentation services </strong> are essential for modern businesses:
            </p>
            <ol className="pt-2 text-white">
              <li><strong>Scalability: </strong> Quickly scale your workforce up or down as your business needs change,
                whether for short-term projects or long-term initiatives
              </li>
              <li><strong>Access to Top Talent: </strong> Augmentation services connect you with experienced
                professionals who can immediately contribute to your business without requiring
                extensive onboarding.</li>
              <li><strong>Cost Efficiency: </strong> Avoid the overhead costs of full-time hires, such as benefits, training,
                and long-term commitments, while still gaining access to highly qualified professionals.</li>
            </ol>
            <p className="text-white pt-3">
              Whether you need extra support for a specific project or long-term expertise in key areas, <strong>staff augmentation services </strong> give you the flexibility to grow your team on your terms.
            </p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire IT Staff Augmentation Services?</h3>
              <p className="pt-3">
                Technology is evolving rapidly, and so are the demands on your IT department. <strong>IT staff
                  augmentation</strong> provides your business with immediate access to skilled tech professionals who
                can integrate seamlessly with your in-house team. Whether you need software developers,
                network engineers, or cybersecurity experts, staff augmentation services ensure you have the
                right people for the job.
              </p>
              <p className="pt-3">Here’s how IT staff augmentation can benefit your business:</p>
              <ol className="pt-2">
                <li><strong>Specialized Expertise: </strong> Get access to specialists with deep experience in your required
                  technologies, whether it’s cloud computing, data analytics, or cybersecurity.</li>
                <li><strong>Faster Project Delivery: </strong> With augmented IT staff, you can speed up project timelines
                  and meet deadlines without compromising on quality or functionality.</li>
                <li><strong>Seamless Integration: </strong> Augmented staff work as an extension of your team, ensuring
                  collaboration, consistency, and knowledge transfer throughout the project lifecycle.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why IT Staff Augmentation is a Smart Choice for Growing Businesses </h3>
              <p className="pt-3 text-white">
                Managing your IT infrastructure in-house can be overwhelming, especially when facing
                unexpected growth or the need for specialized expertise. <strong>IT staff augmentation</strong> gives you the
                power to address these challenges without stretching your existing team too thin.
              </p>
              <p className="pt-3 text-white">
                Here’s why <strong> IT staff augmentation </strong>is the right choice for growing businesses:
              </p>
              <ol className="pt-2 text-white">
                <li><strong>On-Demand Talent: </strong>Whether you need developers for a new software launch or IT
                  support during peak seasons, staff augmentation gives you the flexibility to bring in
                  skilled professionals on short notice.</li>
                <li><strong>Reduced Risk: </strong> Hiring full-time staff is a commitment that comes with risks. With IT
                  staff augmentation, you can bring in the talent you need for specific projects without
                  long-term obligations.</li>
                <li><strong>Flexibility & Control: </strong> Augmented staff are hired to meet your specific needs, allowing
                  you to retain full control over project direction while adding the expertise to execute
                  effectively.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Outsource with Staff Augmentation Providers?</h3>
              <p className="pt-3">
                In an ever-changing business landscape, outsourcing talent is an efficient way to stay ahead of
                the competition. <strong>Staff outsourcing</strong> gives you access to global talent, specialized skills, and cost
                savings while maintaining control over your projects and operations.
              </p>
              <p className="pt-3">
                Here’s why working with <strong>staff augmentation providers</strong> makes sense for your business:

              </p>
              <ol>
                <li><strong>Access to a Larger Talent Pool:</strong> Staff augmentation providers connect you with
                  experienced professionals from diverse backgrounds, ensuring you find the right fit for
                  your needs.</li>
                <li><strong>Efficiency:</strong> Outsourcing staff means you can bypass the time-consuming recruitment
                  process, reducing the time it takes to onboard new team members.
                </li>
                <li><strong>Reduced Costs:</strong> Avoid the long-term financial commitment of hiring full-time
                  employees and the administrative overheads associated with maintaining a larger
                  workforce.</li>

              </ol>


            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">The Benefits of IT Staff Outsourcing</h3>
              <p className="pt-3 text-white">
                Whether your business needs a temporary boost in workforce or long-term IT support, <strong>IT staff
                  outsourcing</strong> offers a reliable, flexible solution. By partnering with an outsourcing provider, you
                can scale your IT capabilities while keeping costs down and maintaining focus on your core
                operations.
              </p>
              <p className="pt-3 text-white">
                Here’s why  <strong>staff outsourcing</strong>  is the right move for your business:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Focus on Core Competencies::</strong> By outsourcing non-core IT tasks, you can allow your
                  internal team to focus on mission-critical projects that drive growth and innovation..</li>
                <li><strong>Cost-Effective Scaling:</strong> Outsourcing provides a scalable model for businesses, allowing
                  you to adjust your workforce based on the changing needs of your company without the
                  need for permanent hires.</li>
                <li><strong>Global Talent Access:</strong> IT staff outsourcing providers offer access to a global network of
                  skilled professionals who can provide round-the-clock support and expertise in the latest
                  technologies.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Work with a Staff Augmentation Services Provider?</h3>
              <p className="pt-3">
                When you partner with a <strong>staff augmentation services provider</strong>, you gain access to a global
                network of professionals who can meet your business’s specific needs. Whether you need
                temporary staff for a short-term project or long-term resources to support ongoing operations,
                staff augmentation offers flexibility and efficiency.
              </p>
              <p className="pt-3">
                Here’s what you can expect from a leading staff augmentation provider:
              </p>
              <ol className="pt-3">
                <li><strong>Tailored Solutions  :</strong>
                  A reputable provider will work closely with you to understand your
                  business objectives and craft customized staffing solutions that align with your goals.</li>
                <li><strong>Tailored Solutions:</strong>
                  By leveraging a deep pool of talent, staff augmentation
                  providers can match you with professionals who have the right skills and experience to
                  drive your projects forward.</li>
                <li><strong>Seamless Integration:</strong>
                  Augmented staff act as an extension of your team, integrating
                  smoothly into your workflows and company culture to deliver optimal results.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Outsourcing Your Staff is a Game Changer </h3>
              <p className="pt-3 text-white">
                In today’s dynamic business environment, having the flexibility to scale your workforce is
                crucial. <strong>Staff outsourcing</strong> allows you to bring in expert professionals without the hassle of full-
                time hiring. By working with a trusted provider, you can ensure that your staffing needs are met
                efficiently, cost-effectively, and with the right level of expertise.
              </p>
              <p className="pt-3 text-white">
                Here’s how outsourcing your staff can help:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Cost-Effective Growth:</strong>
                  With outsourcing, you can grow your business without the
                  financial and administrative burdens that come with traditional hiring.</li>
                <li><strong>Access to Specialized Skills:</strong>
                  Outsourcing providers offer access to professionals with
                  niche skills that may not be available within your existing team, ensuring that your
                  projects benefit from expert knowledge and experience.</li>
                <li><strong>Flexible Staffing Solutions:</strong>
                  Whether you need short-term support for a specific project
                  or long-term resources to scale your operations, outsourcing provides the flexibility to
                  meet your evolving needs.</li>
              </ol>
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Partner with Us?</h3>
              <p className="pt-3">
                At O2P, we understand that your business’s success depends on having the
                right people in place at the right time. That’s why we offer <strong>staff augmentation services</strong> that are
                tailored to your specific needs. Our team of experts is here to help you find the talent you need,
                whether for short-term projects or long-term support.
              </p>
              <p className="pt-3">We specialize in providing scalable, flexible staffing solutions that enable your business to adapt
                to changing market demands without the overhead of traditional hiring. From IT staff
                augmentation to full-scale outsourcing, we’ve got you covered.</p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-center py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Ready to Enhance Your Workforce?
            </h2>
            <p className="text-white">
              If you’re looking for flexible staffing solutions that allow you to scale your operations, <strong>staff
                augmentation services</strong> are the answer. Let us help you find the right talent to drive your
              business forward, without the long-term commitments of full-time hiring.
            </p>
            <p className="text-white pt-3">
              Contact us today to learn more about our <strong>staff augmentation and IT outsourcing services.</strong>
            </p>

          </div>
        </div>
      </div>
      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Staff Augmentation
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default StaffAugmentation;
