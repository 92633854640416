import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import WebDev from "../pages/WebDevelopment";
import Blog from "../pages/Blog";
import Contact from "../pages/Contact";
import BlogDetail from "../pages/Blogdetail";
import ErrorPage from "../pages/ErrorPage";
import Portfolio from "../pages/Portfolio";
import ThankYou from "../pages/ThankYou";
// Admin CRUD 
import AddEditBlog from "../admin/BlogCrud/AddEditBlog";
import AdminAuth from "../admin/Authentication/Auth";
import BlogCRUD from "../admin/BlogCrud/BlogView";
import MetaData from "../admin/Meta/Metas";
import Marketingstrategy from "../pages/Marketingstrategy";
import AmazonMarketing from "../pages/AmazonMarketing";
import BusinessProcess from "../pages/BusinessProcess";
import ServerAdministration from "../pages/ServerAdministration";
import StaffAugmentation from "../pages/StaffAugmentation";
import VideoDevelopment from "../pages/VideoDevelopment";

const Routers = ({ user, handleModalShow, sendForm, sendEmail }) => {
  const userId = user?.uid;

  return <>
    <Routes basename="/home">
      <Route path="/" element={<Home handleModalShow={handleModalShow} sendEmail={sendEmail} sendForm={sendForm} />} />
      <Route path="/home" element={<Home handleModalShow={handleModalShow} />} />
      <Route path="/about-us" element={<About handleModalShow={handleModalShow} sendEmail={sendEmail} sendForm={sendForm} />} />
      <Route path="/web-development" element={<WebDev handleModalShow={handleModalShow} />} />
      <Route path="/marketing-strategy" element={<Marketingstrategy handleModalShow={handleModalShow} />} />
      <Route path="/amazon-marketing" element={<AmazonMarketing handleModalShow={handleModalShow} />} />
      <Route path="/business-process" element={<BusinessProcess handleModalShow={handleModalShow} />} />
      <Route path="/server-adminstration" element={<ServerAdministration handleModalShow={handleModalShow} />} />
      <Route path="/staff-augmentation" element={<StaffAugmentation handleModalShow={handleModalShow} />} />
      <Route path="/video-development" element={<VideoDevelopment handleModalShow={handleModalShow} />} />
      <Route path="/blogs" element={<Blog handleModalShow={handleModalShow} />} />
      <Route path="/blog/:slug" element={<BlogDetail handleModalShow={handleModalShow} />} />
      <Route path="/contact-us" element={<Contact handleModalShow={handleModalShow} />} />
      <Route path="/portfolio" element={<Portfolio handleModalShow={handleModalShow} sendEmail={sendEmail} sendForm={sendForm} />} />
      <Route path="/thank-you" element={<ThankYou handleModalShow={handleModalShow} />} />
      <Route path="*" element={<ErrorPage handleModalShow={handleModalShow} />} />
      {/* Admin CRUD  */}
      <Route
        path="/auth"
        element={
          userId ? <BlogCRUD user={user} /> : <AdminAuth />
        } />
      <Route
        path="/blog-crud"
        element={
          userId ? <BlogCRUD user={user} /> : <Navigate to="/" />
        }
      />
      <Route
        path="/blog-create"
        element={
          userId ? <AddEditBlog user={user} /> : <Navigate to="/" />
        }
      />
      <Route
        path="/blog-update/:id"
        element={
          userId ? <AddEditBlog user={user} /> : <Navigate to="/" />
        }
      />
      <Route path="/meta-datas" element={userId ? (<MetaData />) : (<Navigate to="/" />)} />
      <Route path="/meta-data/:id" element={userId ? (<MetaData />) : (<Navigate to="/" />)} />
      <Route path="/404" element={<ErrorPage handleModalShow={handleModalShow} />} />
    </Routes>
  </>;
};

export default Routers;
