import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const Marketingstrategy = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.MarketingStrategyMetaTitle ? meta[0]?.MarketingStrategyMetaTitle : "Marketing Strategy"}</title>
        <meta name="description" content={meta && meta[0]?.MarketingStrategyMetaDesc ? meta[0]?.MarketingStrategyMetaDesc : "Unlock your brand&#39;s potential with top-tier marketing design and strategy resources. Discover flexible, cost-effective solutions for hiring expert designers and strategists tailored to your business needs"} />
        <meta name="keywords" content={meta && meta[0]?.MarketingStrategyMetaKey ? meta[0]?.MarketingStrategyMetaKey : "marketing design services, hire marketing design expert, marketing strategy design outsourcing, marketing design resource, marketing outsourcing services"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Elevate Your Brand with Expert Marketing
                  Design & Strategy</b>
              </h1>
              <p className="pt-2 text-white">
                In today’s fast-paced world, marketing design isn’t just about making things look good—it’s
                about creating experiences that captivate your audience and move the needle for your business.
                Whether you need eye-catching visuals, a complete brand overhaul, or strategic direction, having
                the right marketing design resources on your team can make all the difference.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>

    

      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-center py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Marketing Design Matters
            </h2>
            <p className="text-white">
              Marketing design is the visual foundation of your brand. From your logo and website to social
              media graphics and ad campaigns, it’s what grabs attention, sparks curiosity, and leads your
              audience to take action. And it’s not just about looking good—great design should communicate
              your message clearly and consistently across all channels.
            </p>
            <p className="text-white pt-3">
              When you hire a <strong>marketing design resource,</strong> you’re investing in someone who will help ensure
              your brand stands out in a crowded marketplace. They’ll work with you to understand your
              goals, target audience, and brand voice to create visuals that don’t just look good—they perform.
            </p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire a Marketing Design Resource?</h3>
              <p className="pt-3">
                We get it—finding the right talent can be tough. That’s where we come in. We specialize in
                <strong>outsourcing marketing design resources,</strong> connecting you with experienced professionals who
                understand how to create compelling visual content that aligns with your brand and drives
                results.
              </p>
              <p className="pt-3">Here’s why hiring a marketing design expert can be a game-changer for your business:</p>
              <ol className="pt-2">
                <li><strong>Expert Skills, Tailored to You: </strong> From web design and social media graphics to print
                  materials, our marketing design experts bring years of experience and know-how to every
                  project.</li>
                <li><strong>Consistent Branding: </strong> Your brand’s visuals need to be cohesive across every touchpoint.
                  Our design resources ensure that no matter where your audience finds you, your brand
                  looks sharp and professional.</li>
                <li><strong>Save Time & Cost: </strong> By outsourcing, you’ll get top-notch design talent when you need
                  it—without the overhead costs of hiring full-time staff. It’s a flexible, cost-effective
                  solution tailored to your needs.</li>
              </ol>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Taking It One Step Further: Marketing Strategy Design </h3>
              <p className="pt-3 text-white">
                Having eye-catching visuals is just one piece of the puzzle. To truly succeed, you need a strategy
                behind your design. That’s where a <strong>marketing strategy design resource </strong> comes in.
              </p>
              <p className="pt-3 text-white">
                A marketing strategist will not only help shape the overall direction of your brand, but they’ll
                also work with your design team to ensure every piece of content, from ad creatives to email
                campaigns, aligns with your business goals. They take a big-picture approach to your marketing,
                making sure that every design decision supports your brand’s long-term success.
              </p>
              
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Hiring a Marketing Strategy Design Resource Matters</h3>
              <ol className="pt-3">
                <li><strong>Strategic Vision:</strong> Your marketing strategy design resource will help you connect your
                  brand’s visuals to your business objectives, ensuring that every campaign has a clear
                  purpose and direction.</li>
                <li><strong>Optimize Performance:</strong> From crafting the perfect messaging to analyzing what works,
                  they’ll help refine your marketing strategy to maximize your results and ROI.
                </li>
                <li><strong>Better Collaboration:</strong> Marketing strategists bring everyone together—from designers to
                  copywriters—making sure every piece of content reflects your brand’s voice and delivers
                  results.</li>

              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Outsource Marketing Design & Strategy?</h3>
              <p className="pt-3 text-white">
                Outsourcing isn’t just a trend—it’s the future of work. Businesses of all sizes are realizing the
                benefits of tapping into external talent pools to get the expertise they need when they need it.
                Here’s why outsourcing your marketing design and strategy makes sense:
              </p>
              <ol className="text-white pt-3">
                <li><strong>Access to Specialized Talent:</strong> Whether you need a designer with niche skills or a
                  seasoned strategist, outsourcing gives you access to a global talent pool.</li>
                <li><strong>Flexibility:</strong> Need someone for a one-time project or ongoing support? Outsourcing
                  allows you to scale your team as needed without the long-term commitment.</li>
                <li><strong>Cost-Effective Solutions:</strong> Get the expertise you need without the cost of full-time hires.
                  Outsourcing lets you pay for what you need when you need it.</li>
              </ol>
              
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Work with Us?</h3>
              <p className="pt-3">
                At Outsourcing to Pakistan, we know that no two businesses are the same. That’s why we take a
                tailored approach to every project, ensuring that we match you with the best marketing design
                and strategy resources to meet your unique needs.
              </p>
              <p className="pt-3">
                Our pool of vetted professionals has experience in every industry and skill set, allowing us to
                connect you with the right talent—whether you’re launching a new product, rebranding, or

                ramping up your marketing efforts. Plus, we’re here for the long haul, providing ongoing support
                to ensure your success.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={woo}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={magento}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Ready to Transform Your Marketing? </h3>
              <p className="pt-3 text-white">
                Let’s make your brand impossible to ignore. Whether you need a designer to breathe new life
                into your visuals or a strategist to guide your marketing efforts, we’ve got you covered.
              </p>
              <p className="pt-3 text-white">
              Get in touch with us today to learn more about our <strong>outsourcing services </strong>and how we can help
              you hire the right marketing design and strategy resources to take your business to the next level.
              </p>
              
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Efficient Collaboration</h3>
              <p className="pt-3">
                Our developers are not just skilled in coding but also in effective collaboration, ensuring that they work seamlessly with your in-house team.
              </p>
             
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Marketing Strategy
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          
          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default Marketingstrategy;
