import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const WebDevelopment = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.WebDevelopmentMetaTitle ? meta[0]?.WebDevelopmentMetaTitle : "Web Development"}</title>
        <meta name="description" content={meta && meta[0]?.WebDevelopmentMetaDesc ? meta[0]?.WebDevelopmentMetaDesc : "Create dynamic, high-performing websites with expert web development services. Specializing in frontend, backend, and full-stack solutions for modern businesses's."} />
        <meta name="keywords" content={meta && meta[0]?.WebDevelopmentMetakey ? meta[0]?.WebDevelopmentMetakey : "Web development, frontend development, backend development, web development services, hire web developers, web development outsourcing"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Build Powerful Websites with Expert Web
                  Development Services</b>
              </h1>
              <p className="pt-2 text-white">
                In the digital-first world, your website is often the first impression customers have of your
                business. To stand out, you need a site that’s not only visually appealing but also fast, secure,
                and user-friendly. That’s where <strong>web development services</strong> come in. Whether you need a simple
                landing page or a complex web application, expert web development ensures your site is
                functional, scalable, and aligned with your business goals.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* service process section start  */}
      <div className="serviceProcess py-5">
        <div className="container">
          <div className="designer-box">
            <div className="title text-center mb-5">
              <h2 className="fs-1">
                <span className="animate">Process</span>
                Web Development
              </h2>
            </div>
            <div className="imgbx text-center">
              <img
                src={webDevProcessImg}
                className="img-fluid"
              />
            </div>
            <div className="mt-5 text-center">
              <button className="main-btn-tn">
                <NavLink to="#" onClick={handleModalShow}>
                  <span>Get Started</span>
                </NavLink>
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* service process section end  */}

      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-left py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Web Development is Critical for Your Business
            </h2>
            <p className="text-white">
              A well-developed website serves as the foundation of your online presence, driving traffic,
              engagement, and conversions. Web development goes beyond design; it’s about building robust,
              scalable solutions that can adapt to your evolving business needs.
            </p>
            <p className="text-white pt-3">Here’s why web development is critical for your business:</p>
            <ol className="text-white pt-3">
              <li>
                <strong>Improved User Experience:</strong> A well-built website ensures users can navigate easily, find
                what they’re looking for, and have a smooth, enjoyable experience.
              </li>
              <li>
                <strong>Performance Optimization:</strong> A slow website can drive away potential customers. Expert
                web development ensures your site is optimized for speed and performance, enhancing
                both user experience and SEO rankings.
              </li>
              <li>
                <strong>Improved User Experience:</strong> A well-built website ensures users can navigate easily, find
                what they’re looking for, and have a smooth, enjoyable experience.
              </li>
            </ol>
            <p className="text-white">Whether you’re a small business or a large enterprise, investing in web development services
              helps you create a professional, secure, and high-performing online presence.</p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire Web Development Services?</h3>
              <p className="pt-3">
                Building a website that meets your business’s needs requires a combination of both <strong>frontend
                  development and backend development.</strong> Together, these two components create a seamless,
                engaging, and functional website that delivers a great user experience.
              </p>
              <p className="pt-3">
                Here’s how <strong> web development services</strong> can benefit your business:
              </p>
              <ol className="pt-3">
                <li>
                  <strong>Custom Solutions:</strong> Every business is unique, and a one-size-fits-all approach won’t cut
                  it. With professional web development, you get custom-built solutions tailored to your
                  specific business requirements.
                </li>
                <li>
                  <strong>Full Control:</strong> From the website’s design to its functionality, a well-executed
                  development plan ensures you have full control over the user experience, site
                  performance, and overall brand presence.
                </li>
                <li>
                  <strong>Scalable Architecture:</strong> As your business grows, your website needs to grow too.
                  Professional web development services create scalable architectures that allow your site
                  to evolve without costly overhauls.
                </li>
              </ol>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Frontend Development is Essential for Engaging Users</h3>
              <p className="pt-3 text-white">
                Your website’s <strong>frontend development</strong> is what users see and interact with. It includes everything
                from design elements and navigation to content layout and responsiveness. An engaging, user-
                friendly frontend ensures that visitors have a positive experience, which translates to higher
                engagement and conversions.
              </p>
              <p className="pt-3 text-white">
                Here’s why frontend development is essential for your website:
              </p>
              <ol className="pt-3 text-white">
                <li>
                  <strong>Responsive Design:</strong> In a world where users access websites on multiple devices,
                  frontend development ensures that your site looks and functions perfectly across
                  desktops, tablets, and smartphones.
                </li>
                <li>
                  <strong>User-Focused Interface:</strong> A well-developed frontend focuses on delivering a smooth,
                  intuitive experience for the user, making it easier for them to navigate your site and take
                  the desired action.
                </li>
                <li>
                  <strong>Optimized Performance:</strong> Frontend development includes optimizing images, code, and
                  scripts to ensure your site loads quickly, reducing bounce rates and improving SEO.
                </li>
              </ol>
              <p className="pt-3 text-white">
                Whether you’re launching a new website or redesigning an existing one, investing in
                professional <strong>frontend development</strong> ensures that your site is visually appealing and user-
                friendly.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Backend Development is Crucial for a Seamless Experience</h3>
              <p className="pt-3">
                While the frontend is what users interact with, the <strong>backend development</strong> is the engine that
                powers it all. A solid backend is essential for ensuring that your website functions smoothly,
                handles data securely, and scales efficiently as your business grows.
              </p>
              <p className="pt-3">Here’s why backend development is crucial for your business:</p>
              <ol className="pt-3 ">
                <li>
                  <strong>Data Management:</strong> Backend development handles all data interactions on your site,
                  from processing customer orders to managing user accounts, ensuring that data is stored
                  securely and accessed efficiently.
                </li>
                <li>
                  <strong>Security:</strong> Your website’s backend must be built with security in mind to protect sensitive
                  information, from customer data to payment details.
                </li>
                <li>
                  <strong>Performance and Reliability:</strong> A strong backend ensures your website runs smoothly,
                  even during high traffic periods. It handles the logic, database interactions, and server
                  requests that keep your site responsive and reliable.
                </li>
              </ol>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={woo}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={magento}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Combine Frontend and Backend Development for Full-Stack Solutions?</h3>
              <p className="pt-3 text-white">
                Combining frontend and backend development creates a full-stack solution, ensuring that your
                website not only looks great but also functions flawlessly behind the scenes. Full-stack
                development covers everything from user interface design to database management, delivering a
                seamless, integrated web experience.
              </p>
              <p className="pt-3 text-white">
                Here’s why full-stack development is beneficial for your business:
              </p>
              <ol className="pt-3 text-white">
                <li>
                  <strong>Efficient Development:</strong> Full-stack developers understand both the frontend and backend,
                  allowing for faster, more efficient development of your website.
                </li>
                <li>
                  <strong>Seamless Integration:</strong> Full-stack development ensures that all components of your
                  website—design, functionality, data management—work together seamlessly, resulting in
                  a cohesive, high-performing site.
                </li>
                <li>
                  <strong>Optimized Performance:</strong> Full-stack development provides the flexibility to integrate new
                  features and technologies as your business needs evolve, ensuring your site stays
                  competitive and relevant.
                </li>
              </ol>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={crm}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={erp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={cloud}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Benefits of Outsourcing Web Development Services</h3>
              <p className="pt-3">
                Managing a web development team in-house can be resource-intensive. By outsourcing <strong>web
                  development services,</strong> you gain access to a dedicated team of professionals who can build,
                optimize, and maintain your site, without the overhead costs of full-time staff.
              </p>
              <p className="pt-3">
                Here’s why outsourcing web development is the smart choice:
              </p>
              <ol className="pt-3">
                <li><strong>Cost Savings:</strong> Outsourcing your web development needs allows you to access highly
                  skilled professionals without the costs of hiring, training, and managing an in-house
                  team.</li>
                <li><strong>Expertise on Demand:</strong> Web development agencies offer access to a wide range of skills,
                  from frontend design to backend development, ensuring you have the expertise needed
                  for each stage of your project.</li>
                <li><strong>Scalability:</strong> Outsourcing allows you to scale your web development efforts up or down
                  based on your project’s requirements, whether you need ongoing support or short-term
                  development services.</li>
              </ol>
             
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Partner with Us for Web Development?</h3>
              <p className="pt-3 text-white">
                At O2P, we specialize in providing comprehensive <strong> web development
                  services</strong> tailored to your unique business needs. Our team of experienced developers excels in
                both <strong>frontend and backend development,</strong> creating custom solutions that enhance user
                experience, optimize performance, and ensure your site is secure and scalable.
              </p>
              <p className="pt-3 text-white"
              >Whether you need a simple website refresh or a full-scale web application, our approach is
                focused on delivering solutions that drive results and support your long-term business goals.</p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wooWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={shopifyWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={phpWhite}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angularWhite}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Build Your Dream Website?</h3>
              <p className="pt-3">
                If you’re looking for expert <strong>web development services</strong> that combine cutting-edge design with
                flawless functionality, we’re here to help. Let us transform your vision into a high-performing
                website that sets you apart from the competition.
              </p>
              <p className="pt-3">
                Contact us today to learn more about our <strong>web development services </strong>and how we can create a
                solution tailored to your needs.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Web Development
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default WebDevelopment;
