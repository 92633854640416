import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const BusinessProcess = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.BusinessProcessMetaTitle ? meta[0]?.BusinessProcessMetaTitle : "Business Process Outsourcing"}</title>
        <meta name="description" content={meta && meta[0]?.BusinessProcessMetaDesc ? meta[0]?.BusinessProcessMetaDesc : "Streamline your operations with expert Business Process Outsourcing (BPO) services. Learn how BPO can boost efficiency and reduce costs for your business"} />
        <meta name="keywords" content={meta && meta[0]?.BusinessProcessMetaKey ? meta[0]?.BusinessProcessMetaKey : "Business process outsourcing, BPO services, outsource business processes, hire BPO services, outsourcing services"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Transform Your Business with Expert
                  Business Process Outsourcing (BPO) Services</b>
              </h1>
              <p className="pt-2 text-white">
                In today's competitive marketplace, businesses need to be lean, efficient, and scalable. One of the
                best ways to achieve this is through Business Process Outsourcing (BPO)—partnering with
                experts to handle non-core tasks while you focus on what you do best. Whether it's customer
                service, HR management, finance, or IT support, outsourcing these processes can save you time,
                reduce costs, and help your business run more smoothly.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>



      {/* info section start  */}
      <div className="info-section py-5">
        <div className="container">
          <div className="content text-left py-3">
            <h2 className="text-white fs-1 pb-3">
              <span className="animate">Outsourced</span>
              Why Business Process Outsourcing (BPO) Matters
            </h2>
            <p className="text-white">
              BPO is more than just delegating tasks it's about building strategic partnerships to drive
              operational efficiency, scalability, and innovation. By outsourcing business processes, you free
              up resources, allowing your team to focus on core business functions that directly impact your
              bottom line..
            </p>
            <p className="text-white pt-3">
              Here’s why <strong>BPO services</strong> are essential for businesses looking to grow and stay competitive:
            </p>
            <ol className="text-white pt-3">
              <li><strong>Cost Efficiency:</strong> Outsourcing your non-core tasks can significantly reduce overhead
                costs. With BPO, you don’t have to invest in expensive infrastructure, technology, or
                full-time staff.
              </li>
              <li><strong>Access to Expertise:</strong> BPO providers are specialists in their fields, bringing expertise, best
                practices, and the latest technology to your business processes.
              </li>
              <li><strong>Focus on Core Activities:</strong> By outsourcing administrative or repetitive tasks, you can
                focus on strategic initiatives that drive growth and revenue.
              </li>
            </ol>
            <p className="text-white pt-3">Whether you’re a startup looking to scale or an established company aiming to improve
              efficiency, BPO services offer flexible, customized solutions to meet your needs.</p>
          </div>
        </div>
      </div>
      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire a BPO Service Provider?</h3>
              <p className="pt-3">
                Managing all aspects of a business in-house can be resource-intensive. That’s why partnering
                with a <strong>BPO service provider</strong> is a smart move. From customer service to payroll processing, the
                right BPO partner can handle your day-to-day operations seamlessly while you concentrate on
                growing your business.
              </p>
              <p className="pt-3">Here’s how a BPO service provider can support your business:</p>
              <ol className="pt-2">
                <li><strong>Customized Solutions:</strong> A good BPO provider will tailor their services to meet your
                  unique business needs, ensuring that the processes they handle are aligned with your
                  goals.</li>
                <li><strong>Advanced Technology: </strong> BPO companies invest in cutting-edge technology to deliver top-
                  tier services, which means your business benefits from the latest innovations without the
                  hefty price tag.</li>
                <li><strong>Scalability: </strong> As your business grows, your BPO provider can scale their services
                  accordingly, providing you with the flexibility you need to handle increased demand.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Outsourcing Business Processes is a Smart Business Strategy </h3>
              <p className="pt-3 text-white">
                Running a business involves countless processes that may not directly contribute to your bottom
                line but are essential for keeping the wheels turning. From managing customer support to
                handling accounts payable, these tasks can eat up valuable time and resources. <strong> Business Process
                  Outsourcing (BPO)</strong> allows you to hand off these tasks to specialists, freeing up your team to
                focus on strategic initiatives.
              </p>
              <p className="pt-3 text-white">
                Here’s why outsourcing business processes is a smart choice:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Improved Efficiency:</strong> BPO providers specialize in streamlining processes and delivering
                  faster, more accurate results than in-house teams often can. This improved efficiency
                  allows your business to operate smoothly without delays or errors..</li>
                <li><strong>Access to Global Talent: </strong> When you outsource, you gain access to a global pool of
                  skilled professionals who can offer round-the-clock services, ensuring your business runs
                  even while you sleep.</li>
                <li><strong>Cost Savings: </strong> Outsourcing reduces the need for full-time employees, office space, and
                  equipment, allowing you to reallocate your resources to more important areas of your
                  business.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Work with Business Process Outsourcing Companies?</h3>
              <p className="pt-3">
                Not all BPO services are created equal. When choosing the right <strong>BPO company</strong> for your
                business, it’s important to find a partner who understands your industry and can offer tailored
                solutions that align with your goals.
              </p>
              <p className="pt-3">
                Here’s what you can expect when you partner with a top-tier BPO provider:
              </p>
              <ol className="pt-3">
                <li><strong>Expertise in Multiple Domains:</strong> Whether you need help with IT support, HR functions,
                  finance, or customer service, BPO companies have specialists in every field ready to
                  support your business..</li>
                <li><strong>Streamlined Operations:</strong> BPO providers use advanced technologies and best practices
                  to streamline processes, reduce operational errors, and enhance productivity.
                </li>
                <li><strong>Data Security & Compliance:</strong> A reputable BPO partner ensures that your business’s
                  sensitive data is handled securely and complies with all industry regulations, giving you
                  peace of mind.</li>

              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">The Benefits of BPO Services</h3>
              <p className="pt-3 text-white">
                The advantages of <strong>BPO services</strong> extend beyond cost savings. By outsourcing, you gain a
                competitive edge, access to specialized skills, and the flexibility to scale your business
                operations efficiently.
              </p>
              <p className="pt-3 text-white">
                Here are just a few benefits of working with a BPO service provider:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Increased Flexibility:</strong> Whether your business needs temporary assistance during peak
                  seasons or long-term support, BPO services can be scaled up or down based on demand.</li>
                <li><strong>Enhanced Focus on Core Activities:</strong> By outsourcing non-core tasks, your team can
                  dedicate more time to innovation, product development, and customer acquisition,
                  ultimately driving growth.</li>
                <li><strong>Seamless Integration:</strong> BPO providers work as an extension of your in-house team,
                  ensuring smooth collaboration and integration of services without disrupting your
                  workflow.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Why Outsource with Us?</h3>
              <p className="pt-3">
                At Outsourcing to Pakistan, we understand that every business is different. That’s why we offer
                customized <strong> Business Process Outsourcing solutions</strong> tailored to your specific needs. With a
                deep pool of industry experts and cutting-edge technology, we’re equipped to handle everything
                from back-office functions to complex processes, so you can focus on what matters
                most—growing your business.
              </p>

              <p className="pt-3">
                Our approach is simple: We become a trusted partner to your business, working closely with you
                to understand your goals, challenges, and operational needs. From there, we craft solutions that
                not only optimize your processes but also boost efficiency and drive results.

              </p>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Ready to Streamline Your Business? </h3>
              <p className="pt-3 text-white">
                If you’re looking to reduce costs, improve efficiency, and scale your business operations,
                partnering with a reliable <strong>BPO service provider</strong> is the way to go. Let us help you take your
                business to the next level with customized outsourcing solutions that align with your goals.
              </p>
              <p className="pt-3 text-white">
                Get in touch with us today to learn more about how our <strong>Business Process Outsourcing services </strong>
                can transform the way you operate.
              </p>
            
            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
   
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Business Process Outsourcing services
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default BusinessProcess;
