import { useState, useEffect } from "react";
import { db } from '../Firebase';
import { collection, getDocs } from "firebase/firestore";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination, Autoplay } from "swiper";
import { NavLink } from "react-router-dom";
import { Helmet } from "react-helmet";
import ReUsable from "../components/ReUsable"
import php from "../assets/__images/php.png"
import node from "../assets/__images/node.png"
import angular from "../assets/__images/angular.png"
import wp from "../assets/__images/wp.png"
import phpWhite from "../assets/__images/php-white.png"
import angularWhite from "../assets/__images/angular-white.png"
import shopifyWhite from "../assets/__images/shopify-white.png"
import wooWhite from "../assets/__images/woo-white.png"
import crm from "../assets/__images/crm.png"
import erp from "../assets/__images/erp.png"
import cloud from "../assets/__images/cloud.png"
import woo from "../assets/__images/woo.png"
import magento from "../assets/__images/magento.png"
import webDevImg from "../assets/images/men-sit.webp"
import webDevProcessImg from "../assets/__images/webDevProcessImg.jpg"
import MultiStepForm from "../components/MultiStepForm";

const ServerAdministration = ({ handleModalShow }) => {

  const [meta, setMeta] = useState([]);

  const fetchMeta = async () => {
    const BlogColl = collection(db, "metaData");
    await getDocs(BlogColl).then((querySnapshot) => {
      const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
      setMeta(newData);
    })
  }
  useEffect(() => {
    fetchMeta();
  }, [])

  return (
    <>
      <Helmet>
        <title>{meta && meta[0]?.ServerAdministrationMetaTitle ? meta[0]?.ServerAdministrationMetaTitle : "Server Administration"}</title>
        <meta name="description" content={meta && meta[0]?.ServerAdministrationMetaDesc ? meta[0]?.ServerAdministrationMetaDesc : "Ensure optimal performance with expert server management services. Learn how outsourcing server administration can enhance security, efficiency, and reliability for your business."} />
        <meta name="keywords" content={meta && meta[0]?.ServerAdministrationMetaKey ? meta[0]?.ServerAdministrationMetaKey : "Server management, server management services, server management resourcing, server administration outsourcing, hire server management experts"} />
      </Helmet>
      <div className="web-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col content text-center">
              <h1>
                <b>Keep Your Business Running Smoothly with
                  Expert Server Management Services
                </b>
              </h1>
              <p className="pt-2 text-white">
                In today’s digital age, your servers are the backbone of your business operations. Ensuring they
                run efficiently, securely, and reliably is critical to your success. But managing servers in-house
                can be costly, time-consuming, and technically complex. That’s where server management
                services come in. By outsourcing server administration, you gain access to specialized expertise,
                advanced tools, and 24/7 support—all designed to keep your business running smoothly.
              </p>
              <div className="mt-5">
                <button className="main-btn-tn">
                  <NavLink to="#" className="text-white" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className="multivendor-plan-main py-5">
        <div className="container">
          <div className="row text-left align-items-center">
            <div className="col-md-2"></div>
            <div className="col-md-8 content">
              <h3> Why Server Management is Crucial for Your Business</h3>
              <p className="pt-3">
                <strong>Server management</strong> is about more than just keeping your servers online. It’s about optimizing
                performance, securing data, and ensuring that your infrastructure can scale as your business
                grows. Whether you’re running on-premise servers, cloud-based solutions, or a hybrid setup,
                effective server management ensures your systems are operating at peak performance.
              </p>
              <p className="pt-3">
                Here’s why <strong>server management</strong> is essential:
              </p>
              <div className="row">
                <div className="col-md-8">
                <ol className="pt-3">
                <li><strong>Enhanced Security:</strong> Servers are often the target of cyberattacks. Proper server
                  management includes regular security patches, updates, and monitoring to safeguard your
                  sensitive data.
                </li>
                <li><strong>Improved Performance:</strong >A well-managed server means faster response times, fewer
                  crashes, and better overall system reliability, keeping your business running smoothly
                  24/7.
                </li>
                <li><strong>Reduced Downtime:</strong> With proactive server monitoring, issues can be identified and
                  resolved before they cause costly downtime, saving your business time and money.
                </li>
              </ol>
                </div>
              </div>

            </div>
            <div className="col-md-2"></div>
          </div>
        </div>
      </div>
      {/* info section start  */}

      {/* info section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Hire Server Management Services?</h3>
              <p className="pt-3">
                Managing servers in-house can be complex and resource-intensive. Outsourcing to a professional
                <strong>server management service</strong> provider allows you to focus on your core business while ensuring
                that your servers are well-maintained, secure, and optimized for performance.
              </p>
              <p className="pt-3">Here’s how server management services can benefit your business:</p>
              <ol className="pt-2">
                <li><strong>Proactive Monitoring:</strong> Around-the-clock server monitoring helps identify and resolve
                  potential issues before they lead to disruptions.</li>
                <li><strong>Regular Maintenance & Updates: </strong> Keeping your servers up-to-date with the latest
                  patches and security protocols ensures optimal performance and protection against
                  threats.</li>
                <li><strong>Expert Support: </strong> Server management providers offer a team of experienced professionals
                  who can troubleshoot issues, provide guidance, and ensure that your servers are
                  configured correctly.</li>
              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Server Management Resourcing is a Smart Move</h3>
              <p className="pt-3 text-white">
                As your business grows, so does the complexity of managing your IT infrastructure. With <strong> server
                  management resourcing,</strong> you gain access to a dedicated team of experts who will manage every
                aspect of your servers—from maintenance and security to optimization and support.
              </p>
              <p className="pt-3 text-white">
                Here’s why <strong>server management resourcing</strong> is a smart choice:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Access to Expertise:</strong> Server management requires a specialized skill set, and resourcing
                  allows you to tap into a pool of seasoned professionals without the overhead of hiring in-
                  house staff.</li>
                <li><strong>Access to Global Talent: </strong> As your business expands, your server needs will grow too. Resourcing
                  provides the flexibility to scale your server management capabilities up or down based on
                  your current needs.</li>
                <li><strong>Cost-Effective: </strong> Instead of investing in full-time IT staff, server management resourcing
                  allows you to only pay for the services you need, making it a cost-effective solution for
                  businesses of all sizes.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Benefits of Outsourcing Server Administration</h3>
              <p className="pt-3">
                Running a business often means wearing many hats, but managing servers shouldn’t have to be
                one of them. By <strong> outsourcing server administration,</strong> you can delegate the responsibility of
                managing your servers to experts, freeing up your time and resources for more strategic
                activities.
              </p>
              <p className="pt-3">
                Here’s why outsourcing server administration is a smart decision:
              </p>
              <ol className="pt-3">
                <li><strong>24/7 Support:</strong> Server issues don’t follow a 9-to-5 schedule. Outsourced server
                  management provides round-the-clock support, ensuring that your servers are always up
                  and running, even outside business hours.</li>
                <li><strong>Expert Troubleshooting:</strong> From performance tuning to security hardening, outsourcing
                  ensures your server administration is handled by professionals who know how to keep
                  things running smoothly.
                </li>
                <li><strong>Focus on Core Business:</strong> Server management can be time-consuming. Outsourcing lets
                  you focus on your business while experts manage your IT infrastructure.</li>

              </ol>

            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* application plan section start  */}
      <div className="application-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Work with a Server Management Outsourcing Provider?</h3>
              <p className="pt-3 text-white">
                Not all <strong>server management outsourcing providers</strong> are the same. It’s important to choose a
                partner who understands your business and has the expertise to provide tailored server
                management solutions that align with your goals.
              </p>
              <p className="pt-3 text-white">
                Here’s what you can expect when you work with a professional server management provider:
              </p>
              <ol className="pt-3 text-white">
                <li><strong>Proactive Solutions:</strong> A great provider doesn’t just fix issues—they prevent them. With
                  proactive monitoring and maintenance, they can identify potential problems before they
                  impact your business.</li>
                <li><strong>Cutting-Edge Technology::</strong> Outsourcing providers stay at the forefront of the latest
                  server management tools and technologies, ensuring your infrastructure benefits from the
                  best solutions available.</li>
                <li><strong>Secure Infrastructure:</strong> Data security is paramount. Outsourcing to a provider ensures
                  that your servers are protected with the latest security protocols, reducing the risk of
                  cyberattacks.</li>
              </ol>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* application plan section end  */}

      {/* multivendor plan section start  */}
      <div className="multivendor-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>The Benefits of Server Management Outsourcing</h3>
              <p className="pt-3">
                <strong> Server management outsourcing</strong> offers a wide range of benefits for businesses looking to
                optimize their IT infrastructure without the burden of managing it in-house.
              </p>

              <p className="pt-3">
                Here’s why outsourcing is the right choice:

              </p>
              <ol className="pt-3">
                <li><strong>Cost Savings:</strong> By outsourcing, you avoid the costs associated with hiring and training
                  full-time IT staff. Plus, you gain access to experts at a fraction of the cost.</li>
                <li><strong>Tailored Solutions:</strong> Every business is different, and so are your server management
                  needs. Outsourcing providers offer customized solutions that are designed to fit your
                  business requirements.</li>
                <li><strong>Minimized Downtime:</strong> With a dedicated team monitoring your servers, issues are
                  resolved faster, minimizing downtime and ensuring your business stays up and running.</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
      {/* multivendor plan section end  */}

      {/* starter plan section start  */}
      <div className="starter-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-7 content">
              <h3 className="text-white">Why Partner with Us? </h3>
              <p className="pt-3 text-white">
                At Outsourcing to Pakistan, we specialize in providing comprehensive server management
                services that are tailored to your business. Whether you need full-time server administration or
                occasional support, our team of experts is here to help you keep your servers secure, efficient,
                and reliable.
              </p>
              <p className="pt-3 text-white">
                We offer a range of services, including server monitoring, maintenance, security updates, and
                performance optimization. Our approach is proactive—meaning we work to prevent issues
                before they impact your business, allowing you to operate with confidence.
              </p>

            </div>
            <div className="col-md-5"></div>
          </div>
        </div>
      </div>
      {/* starter plan section end  */}

      {/* light plan section start  */}
      <div className="light-plan py-5">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-5"></div>
            <div className="col-md-7 content">
              <h3>Ready to Optimize Your Server Management?</h3>
              <p className="pt-3">
                If you’re looking to streamline your operations, enhance security, and reduce the burden of
                managing your servers in-house, outsourcing your server management is the solution. Let us
                help you ensure your IT infrastructure runs smoothly, so you can focus on what really
                matters—growing your business.
              </p>
              <p className="pt-3">
                Contact us today to learn more about our server management services and how we can tailor a
                solution to meet your needs.
              </p>
              {/* <ul className="pt-3">
                <li>
                  <img
                    className="img-fluid"
                    src={wp}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={php}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={angular}
                  />
                </li>
                <li>
                  <img
                    className="img-fluid"
                    src={node}
                  />
                </li>
              </ul>
              <div className="mt-3 ms-5">
                <button className="main-btn-tn">
                  <NavLink to="#" onClick={handleModalShow}>
                    <span>Get Started</span>
                  </NavLink>
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      {/* light plan section end  */}

      <div className="why-choose-us testimonials py-5">
        <div className="container">
          <div className="title text-center mb-3">
            <h2>
              Why Choose Us
            </h2>
          </div>
          <div className="row">
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Proven Track Record</h6>
                <p className='py-3'>
                  With a history of successful projects, we have the experience and expertise to handle complex web development tasks.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Quality Assurance</h6>
                <p className='py-3'>
                  Our commitment to quality is unwavering. We follow best practices and quality standards to deliver high-quality web solutions.
                </p>
              </div>
            </div>
            <div className="col-md-4">
              <div className="testi-box shadow_sm m-2">
                <h6>Dedicated Support</h6>
                <p className='py-3'>
                  We offer 24/7 support to address your concerns and ensure your projects run smoothly.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* plans section start  */}
      <div className="plans-slide py-5">
        <div className="container">
          <div className="title text-center px-5">
            <h2 className="mb-3">
              <span className="animate">Plans</span>
              Business Process Outsourcing services
            </h2>
            <p>
              Our pricing plans are personalized to meet your business objective
              and add value to it. Choose a package that best fits your
              requirement and get started.
            </p>
          </div>

          {/* <Swiper
            modules={[Navigation, Pagination, Autoplay]}
            spaceBetween={20}
            slidesPerView={3}
            grabCursor={true}
            centeredSlides={true}
            loop={true}
            navigation
            // onSlideChange={() => console.log("slide change")}
            // onSwiper={(swiper) => console.log(swiper)}
            autoplay={{ delay: 2000 }}
            breakpoints={{
              0: {
                slidesPerView: 1,
              },
              768: {
                slidesPerView: 2,
              },
              1024: {
                slidesPerView: 3,
              },
            }}
          >
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">Agency Startup</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 2,500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="plan-box">
                <h6 className="mb-4">White Labelling</h6>
                <ul>
                  <li>5 to 10 Pages with Admin Panel</li>
                  <li>Custom Design &amp; HTML</li>
                  <li>3 Sliding Banners</li>
                  <li>API Integration</li>
                  <li>Basic SEO Setup</li>
                  <li>Mobile Responsive</li>
                  <li>Free Hosting with 10 Emails</li>
                  <li>Free Maintenance (15 Days)</li>
                  <li>Delivery (1 month)</li>
                </ul>
                <p className="fs-4 fw-bold">USD 500</p>
                <div className="capsule-normal-btn mt-4">
                  <NavLink to="#" onClick={handleModalShow}>Get Started</NavLink>
                </div>
              </div>
            </SwiperSlide>
          </Swiper> */}

          <div>
            <div className="row pt-4 justify-content-center">
              <div className="col-md-6">
                <div className="card bg_dark">
                  <div className="card-header bg-black border-0">
                    <h3 className='text-white text-center'>Contact us</h3>
                  </div>
                  <div className="card-body">
                    <MultiStepForm PageNameTracking="Web development Page" />
                  </div>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
      {/* plans section end  */}

      <ReUsable handleModalShow={handleModalShow} />


    </>
  );
};

export default ServerAdministration;
