import { useState, useEffect, useRef } from "react";
import Header from "./Header";
import "../assets/css/main.css";
import "../assets/css/responsive.css";
import Routers from "../Router/Routers";
import Footer from './Footer'
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Logo from "../assets/images/logo-lite.webp";
import { useNavigate } from "react-router-dom";

const Layouts = ({ user, handleLogout }) => {

  const [show, setModalShow] = useState(false);
  const handleModalClose = () => setModalShow(false);
  const handleModalShow = () => setModalShow(true);

  const navigate = useNavigate();

  // email start
  const sendForm = useRef();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_5h2m03q",
        "template_0ahc1wn",
        sendForm.current,
        "1AZnpvAoFrweJXY5R"
      )
      .then(
        (result) => {
          toast.info("We will notify you soon");
          setModalShow(false);
          navigate("/thank-you")
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  // email / 

  // popup start
  const sendPopupForm = useRef();

  const sendPopupEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_5h2m03q",
        "template_0ahc1wn",
        sendForm.current,
        "1AZnpvAoFrweJXY5R"
      )
      .then(
        (result) => {
          toast.info("We will notify you soon");
          setModalShow(false);
          navigate("/thank-you")
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  };
  // popup / 

  useEffect(() => {
    const timer = setTimeout(() => {
      setModalShow(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <>
      <Header
        user={user}
        handleLogout={handleLogout}
        handleModalShow={handleModalShow}
      />
      <div>
        <Routers user={user} sendForm={sendForm} sendEmail={sendEmail} handleModalShow={handleModalShow} />
      </div>
      <Footer />

      {/* modal start for email  */}
      <Modal show={show} onHide={handleModalClose} className="popup-box">
        <Modal.Header closeButton>
          <Modal.Title>
            <img
              src={Logo}
              className="img-fluid"
              // style={{height:"54px", width:"198px"}}
            />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form ref={sendPopupForm} onSubmit={sendPopupEmail}>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                type="text"
                name="user_name"
                placeholder="Full Name"
                className="shadow-none"
                required
              />
              <Form.Control
                type="text"
                name="PageNameTracking"
                hidden
                value="Popup Form"
                className="shadow-none"
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <Form.Control
                type="email"
                name="user_email"
                placeholder="Enter Your email"
                className="shadow-none"
                required
              />
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="tel"
                    name="user_phone_number"
                    placeholder="phone number"
                    className="shadow-none"
                    required
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3" controlId="formBasicPassword">
                  <Form.Control
                    type="text"
                    name="user_company_name"
                    placeholder="company name"
                    className="shadow-none"
                    required
                  />
                </Form.Group>
              </Col>
            </Row>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control type="text" name="subject" placeholder="Subject"
                className="shadow-none"
                required />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formBasicPassword">
              <Form.Control
                as="textarea"
                name="user_message"
                rows={4}
                className="shadow-none"
                placeholder="type Message"
                required
              />
            </Form.Group>
            <Button type="submit" className="popup-submit">
              <span> Submit</span>
            </Button>
          </Form>
        </Modal.Body>
      </Modal>
      {/* modal end for email  */}
    </>
  );
};

export default Layouts;
